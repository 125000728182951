.chat-container {
  width: 100%;
  margin: auto;
  padding: 10px;
}

.typing-animation {
  float: right;
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  height: 20px;
  animation: typing 1s infinite;
}

@keyframes typing {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

/* customLoading.css */
/* #loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: '100px';
  background: 'cyan';
  height: 100vh; 
  position: relative;
  }*/
   
  .sign {
    font-size: 24px;
  }
  
  .dot {
    display: inline-block;
    width: 10px;
    animation: loading 1s infinite;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes loading {
    0%, 20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
.sign {
    font-family: coda, basic;
    position: fixed;
    width: 100%;
    top:45%;
   
    text-align: center;
    z-index:1001;
  }
  
  #loader-wraper {
  position: fixed;
  top:0;
  left:0;
  background-color: none;
  width: 100%;
  height: 100%;
  z-index: 1000;

  }
  #loader {
  display: block;
  position: relative;
  left: 44%;
  top: 42%;
  width: 250px;
  height: 250px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3498db;
  z-index:1001;
  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
            animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }
  
  #loader:before {
  content: "";
  position: absolute;
  top:10px;	
  left:10px;
  right: 10px;
  bottom:10px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #c398e8;
  -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
            animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }
  
  #loader:after {
  content: "";
  position: absolute;
  top:20px;
  left:20px;
  right: 20px;
  bottom:20px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #0342fc;
  -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
            animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }
  @-webkit-keyframes spin {
    0%   { 
    -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
            transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% { 
    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
            transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
  }
  @keyframes spin {
    0%   { 
    -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
            transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% { 
    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
            transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
  }
  
  #loader-wraper .loader-section {
  position: fixed;
  top: 0;
  width:51%;
  height: 100%;
 
  z-index: 1000;
  }
  
  #loader-wraper .loader-section.section-left {
  left:0;
  }
  #loader-wraper .loader-section.section-right {
  right:0;
  }
  
  .loaded #loader-wraper .loader-section.section-left {
  -webkit-transform: translateX(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: translateX(-100%);  /* IE 9 */
            transform: translateX(-100%);  /* Firefox 16+, IE 10+, Opera */
            
             -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);  /* Android 2.1+, Chrome 1-25, iOS 3.2-6.1, Safari 3.2-6  */
            transition: all 0.3s 0.7s cubic-bezier(0.645, 0.045, 0.355, 1.000);  /* Chrome 26, Firefox 16+, iOS 7+, IE 10+, Opera, Safari 6.1+  */
            }
            
            .loaded #loader-wraper .loader-section.section-right {
  -webkit-transform: translateX(100%);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: translateX(100%);  /* IE 9 */
            transform: translateX(100%);  /* Firefox 16+, IE 10+, Opera */
            
             -webkit-transition: all 0.3s 0.3s ease-out;  /* Android 2.1+, Chrome 1-25, iOS 3.2-6.1, Safari 3.2-6  */
            transition: all 0.3s 0.3s ease-out;  /* Chrome 26, Firefox 16+, iOS 7+, IE 10+, Opera, Safari 6.1+  */
            }
            .loaded #loader {
            opacity: 0;
             -webkit-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1.000);  /* Android 2.1+, Chrome 1-25, iOS 3.2-6.1, Safari 3.2-6  */
            transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1.000);  /* Chrome 26, Firefox 16+, iOS 7+, IE 10+, Opera, Safari 6.1+  */
            }
            .loaded #loader-wraper {
            visibility: hidden;
            
            -webkit-transform: translateY(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: translateY(-100%);  /* IE 9 */
            transform: translateY(-100%);  /* Firefox 16+, IE 10+, Opera */
            
             -webkit-transition: all 0.3s 1s ease-out;  /* Android 2.1+, Chrome 1-25, iOS 3.2-6.1, Safari 3.2-6  */
            transition: all 0.3s 1s ease-out;  /* Chrome 26, Firefox 16+, iOS 7+, IE 10+, Opera, Safari 6.1+  */
            }